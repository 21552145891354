
.menu {
    width: 150px !important;
    height: auto !important;
    margin: 0;
  /* use absolute positioning  */
    position: absolute;
    padding: 0;
  }
  .menu ul {
   list-style: none;
   margin: 0;
   padding: 0;
   width:100%
  }
  .menu ul li{
    padding: 0.5em;
    text-align: center;
   }
   .menu ul li:hover{
    background-color: #212529;
      color: #ccc;
   }