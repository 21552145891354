:root {
  --triangle-size: 9px;
}

.icon-button {
  width: 0px;
  height: 0px;
  border-top: var(--triangle-size) solid transparent;
  border-left: var(--triangle-size) solid black;
  border-right: var(--triangle-size) solid transparent;
  border-bottom: var(--triangle-size) solid transparent;
  transition-duration: 200ms;
  margin-top:4px;
}

.rotate{
  transform: rotate(90deg);
}