.menu {
    font-size: 14px;
    background-color: #fff;
    border-radius: 2px;
    padding: 5px 10px 5px 10px;
    width: 150px;
    height: auto;
    margin: 0;
  /* use absolute positioning  */
    position: absolute;
    list-style: none;
    box-shadow: 0 0 20px 0 #ccc;
    opacity: 1;
    transition: opacity 0.5s linear;
    z-index: 100000;
  }

  li:hover{
      background-color: #212529;
      color: #ccc;
  }